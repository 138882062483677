import axios from "axios";
import configs from "../config/index";
import CryptoJS from "crypto-js";
import { CRYPTO_KEY } from "../config/env";
// import { deepClone } from "../utils/common.js"
window.ajaxNum = 0; //loading接口计数
// import router from '../router'
// import { Loading, MessageBox, Message } from 'element-ui'
import { Loading, Message, MessageBox } from "element-ui";
let loadingInstance, //记录页面中存在的loading
  loadingCount = 0; //记录当前正在请求的数量

// 检查 http 状态和 code 的状态
function checkStatus(response) {
  // 发生错误(http状态码不是200的情况)，直接 reject,
  // if (response.status < 200 || response.status > 300)
  //     return Promise.reject(response.data || response);
  return response;
}

const normalAxios = axios.create({
  baseURL: configs.baseUrl,
  timeout: 3 * 60000,
  headers: {},
  // withCredentials: true,
});
normalAxios.interceptors.request.use(
  (config) => {
    const szz_v1_token_expiration_time = sessionStorage.getItem(
      "szz_v1_token_expiration_time"
    );

    if (szz_v1_token_expiration_time) {
      const t = 1000 * 60 * 5; // 毫秒，5分钟

      if (szz_v1_token_expiration_time - t < new Date().getTime()) {
        MessageBox.alert("登录超时，请退出重新登录", "提示", {
          confirmButtonText: "确定",
          callback: () => {
            sessionStorage.clear();
            window.location.href = `${window.location.protocol}//${window.location.hostname}:888`;
          },
        });
        return false;
      }
    }

    // 封装loadding
    if (loadingCount == 0) {
      // console.log("有loading了")
      // console.log("打开时" + loadingCount)
      loadingInstance = Loading.service({
        lock: true,
        customClass: "z-index999",
        text: "数据加载中，请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    }
    loadingCount++;

    config.headers = {
      ...config.headers,
      token: sessionStorage.getItem("token") || "",
    };
    // 拦截加密
    const key = CryptoJS.enc.Utf8.parse(CRYPTO_KEY);
    let srcs, encrypted;
    let params = config.data;

    for (let item in params) {
      if (
        typeof params[item] != "undefined" &&
        params[item] != "undefined" &&
        item != "page" &&
        item != "pageSize"
      ) {
        srcs = CryptoJS.enc.Utf8.parse(params[item]);
        encrypted = CryptoJS.AES.encrypt(srcs, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }).toString();
        params[item] = encrypted;
      }
    }
    config.data = params;
    return config;
  },
  (err) => {
    console.log("axois error!", err);
    // 报错的时候关闭loadding
    loadingCount--;
    // console.log("关闭时的"+loadingCount)
    if (loadingInstance && loadingCount == 0) {
      loadingInstance.close();
    }
  }
);

normalAxios.interceptors.response.use(
  (resp) => {
    // 处理loadding
    loadingCount--;
    // console.log("关闭时的"+loadingCount)
    if (loadingInstance && loadingCount == 0) {
      loadingInstance.close();
    }

    window.ajaxNum--;
    if (window.ajaxNum < 1) {
      window.ajaxNum = 0;
    }
    if (resp.data instanceof Blob) {
      return resp.data;
    }
    if (
      resp.data.code === 1 ||
      resp.data.msg == "请求成功" ||
      resp.data.msg == "查询成功！" ||
      resp.data.msg == "删除成功"
    ) {
      return resp.data;
    } else {
      Message.error(resp.data.msg);
      console.log(resp.data);
      // if (resp.data.code == 2) {

      //   MessageBox.confirm('登录超时，请退出重新登录', '提示', {
      //     confirmButtonText: '确定',
      //     showClose: false,
      //     distinguishCancelAndClose: true,
      //     type: 'error'
      //   }).then(() => {
      //     sessionStorage.clear()

      //   }).catch(() => {

      //   });
      // }

      return Promise.reject(resp.data.msg);
    }
  },
  (ex) => {
    // 报错的时候关闭loadding
    loadingCount--;
    // console.log("关闭时的"+loadingCount)
    if (loadingInstance && loadingCount == 0) {
      loadingInstance.close();
    }
    return Promise.reject(ex);
  }
);

const api = (url, newOptions) => {
  let _axios = null;
  if (typeof url === "object") {
    _axios = normalAxios({ ...url });
  } else {
    _axios = normalAxios({ url, ...newOptions });
  }
  _axios = _axios.then(checkStatus).then((resp) => resp);
  return _axios;
};

api.get = (url, newOptions) => api(url, { ...newOptions, method: "get" });
api.download = (url, newOptions) =>
  api(url, { ...newOptions, method: "get", responseType: "blob" });
api.post = (url, newOptions) => api(url, { ...newOptions, method: "post" });

export default api;
