export default {
  // 根据启动的本地的端口号更改
  baseUrl: "https://www.suizz.beifen.hcxtec.com",
  realUrl: "https://www.suizz.beifen.hcxtec.com",
  // baseUrl: "https://suizz.ceprei.com:999",
  // realUrl: "https://suizz.ceprei.com:999",

  // 管理后台接口
  baseUrl2: "https://www.szz.manage.beifen.hcxtec.com",
  realUrl2: "https://www.szz.manage.beifen.hcxtec.com",
  // baseUrl2: "https://suizz.ceprei.com:9204",
  // realUrl2: "https://suizz.ceprei.com:9204",

  loginSession: "loginToken", // 登录token
  loginStatus: "loginStatus", // 登录身份
  loginMsg: "loginMsg", // 登录信息
  loginAvatar: "loginAvatar", // 登录头像
  loginNickname: "loginNickname", // 登录名称
  loginStu: "loginStu", // 登录权限
  aesPrivateKey: "IJfV89KGEBLIyfCa",
  randomStr: "5qLbIAeTWo",

  netLink: "suizz.ceprei.com",

  // 行业选项
  hyOptions: [
    "煤炭/黑色金属矿开采/石油天然气开采",
    "黑色金属",
    "有色金属",
    "石化化工",
    "建材",
    "医药",
    "纺织",
    "家电",
    "食品",
    "烟草",
    "轻工",
    "机械",
    "汽车",
    "航天/航空",
    "船舶",
    "轨道交通",
    "电子",
    "电力",
    "热力和燃气",
    "建筑业",
    "农业",
    "其他",
  ],
  // 领域选项
  lyOptions: [
    "安全生产",
    "节能减排",
    "质量管控",
    "供应链管理",
    "研发设计",
    "生产制造",
    "运营管理",
    "仓储物流",
    "运维服务",
    "其他",
  ],
  // 区域选项
  areaOptions: [
    "荔湾区",
    "越秀区",
    "海珠区",
    "天河区",
    "白云区",
    "黄浦区",
    "番禺区",
    "花都区",
    "南沙区",
    "从化区",
    "增城区",
  ],
};
