<template>
  <div class="sbzj-main" :style="$bgImg('newsBg.png')">
    <div class="container">
      <div class="list flex ">
        <div
          class="item flex column bewteen"
          v-for="(item, index) in applicationList"
          :key="index"
          :style="
            isShowBg && itemIndex == index
              ? $bgImg('sbitem-activity.png')
              : $bgImg('sbitem-unactivity.png')
          "
          @mouseover="handleMouseOver(index)"
          @mouseleave="handleMouseLeave"
        >
          <div class="title">{{ item.title }}</div>
          <div class="btns flex">
            <el-button
              type="primary"
              class="pri-btn"
              @click="goApplication(item, 1)"
              >{{ item.btnName1 }}</el-button
            >
            <el-button @click="toList()" class="cancel-btn">{{
              item.btnName2
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applicationList: [
        // {
        //   title: "数字化产品入库申报",
        //   btnName1: "申报",
        //   btnName2: "查看",
        //   type: 1,
        // },
        {
          title: "试点意向企业入库申报",
          btnName1: "填写",
          btnName2: "查看",
          type: 1,
        },
      ],
      isShowBg: false,
    };
  },
  methods: {
    toList() {
      this.$router.push({ name: "enterpriseSbzjList" });
    },
    toForm() {
      this.$router.push({ name: "enterpriseSbzjForm" });
    },
    handleMouseOver(i) {
      this.isShowBg = true;
      this.itemIndex = i;
    },
    handleMouseLeave() {
      this.isShowBg = false;
    },
    goApplication(item, type) {
      if (item.type == 1) {
        this.$router.push({
          name: "enterpriseSbzjForm",
          // type = 1为申报
          // type = 2为查看
          query: {
            type,
          },
        });
      } else if (item.type == 2) {
        this.$router.push({
          name: "enterpriseMcbForm",
          // type = 1为申报
          // type = 2为查看
          query: {
            type,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sbzj-main {
  min-height: calc(100vh - 208px);
  background: #f0f5ff;
  background-size: cover;
  background-attachment: fixed;
  font-family: Microsoft YaHei;
  .container {
    width: 84%;
    margin: 0 auto;
    .list {
      width: 100%;

      .item {
        width: 33%;
        height: 348px;
        margin-right: 0.8%;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
        border-radius: 10px;
        padding: 50px 20px 30px;
        box-sizing: border-box;
        &:nth-child(3n) {
          margin-right: 0;
        }
        .title {
          font-weight: 600;
          line-height: 1.5;
          font-size: 22px;
        }
        .btns {
          justify-content: flex-end;
          .cancel-btn {
            background-color: #e2e2e2;
            color: #333;
            &:hover {
              background-color: #efefef;
              color: #333;
            }
          }
        }
      }
    }
  }
}
</style>
