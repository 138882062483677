var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",style:(_vm.$bgImg('sbzj-bg.png'))},[_c('div',{staticClass:"same-container"},[_c('div',{staticClass:"size-container"},[_c('div',{staticClass:"public-container"},[_c('div',{staticClass:"top-nav"},[_c('el-breadcrumb',{attrs:{"separator":">"}},[_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/government/sbzj',
              }}},[_vm._v("申报征集")]),(_vm.listType == 1)?_c('el-breadcrumb-item',[_vm._v("数字化产品入库申报")]):(_vm.listType == 2)?_c('el-breadcrumb-item',[_vm._v(" 试点意向企业入库申报")]):_vm._e()],1)],1),_c('div',{staticClass:"tabs"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"审核通过","name":"shtg"}}),_c('el-tab-pane',{attrs:{"label":"审核不通过","name":"shbtg"}}),_c('el-tab-pane',{attrs:{"label":"待审核","name":"dsh"}})],1)],1),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end","width":"100%","margin":"10px 0"}},[_c('el-switch',{attrs:{"active-text":"只看扶持产品","active-color":"#205dc3","inactive-color":"#999"},on:{"change":_vm.fuchiOnly},model:{value:(_vm.isFuChiOnly),callback:function ($$v) {_vm.isFuChiOnly=$$v},expression:"isFuChiOnly"}})],1),_c('div',{staticClass:"list-content"},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"list-item",style:(_vm.$bgImg('bg_paper.png'))},[_c('div',{staticClass:"item-left"},[_c('div',{staticClass:"title"},[(_vm.listType == 1)?_c('span',[_vm._v(_vm._s(item.company_name)+" "+_vm._s("（" + item.product_name + "）"))]):(_vm.listType == 2)?_c('span',[_vm._v(_vm._s(item.enterprise_name))]):_vm._e()]),(_vm.listType == 1)?_c('div',{staticClass:"auditStatus"},[(item.audit_status == 1)?_c('span',{staticStyle:{"color":"orange"}},[_vm._v("待审核")]):(item.audit_status == 2)?_c('span',{staticStyle:{"color":"green"}},[_vm._v("审核通过")]):(item.audit_status == 3)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("审核不通过")]):_vm._e()]):_vm._e(),(_vm.listType == 2)?_c('div',{staticClass:"auditStatus"},[(
                    item.company_digitalize_agree_select.audit_status == 1
                  )?_c('span',{staticStyle:{"color":"orange"}},[_vm._v("待审核")]):(
                    item.company_digitalize_agree_select.audit_status == 2
                  )?_c('span',{staticStyle:{"color":"green"}},[_vm._v("审核通过")]):(
                    item.company_digitalize_agree_select.audit_status == 3
                  )?_c('span',{staticStyle:{"color":"red"}},[_vm._v("审核不通过")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"time"},[_c('img',{staticClass:"time-icon",attrs:{"src":_vm._f("staticMedia")('time.png')}}),_c('span',[_vm._v(_vm._s(item.updatetime))])])]),_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"btns-container"},[_c('el-button',{staticClass:"pri-btn",staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.gotoForm(item)}}},[_vm._v("查看详情")]),_c('el-popover',{ref:item.id + 'popover',refInFor:true,attrs:{"placement":"top","width":"160"}},[_c('p',[_vm._v("确定删除吗？")]),_c('div',{staticStyle:{"text-align":"right","margin":"0"}},[_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){_vm.$refs[((item.id) + "popover")].doClose()}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.deleteItem(((item.id) + "popover"), item)}}},[_vm._v("确定")])],1),_c('el-button',{attrs:{"slot":"reference","type":"danger"},slot:"reference"},[_vm._v("删除")])],1)],1),(item.try_status == 1)?_c('div',{staticClass:"try-status",staticStyle:{"margin-top":"10px"}},[_c('el-tag',[_vm._v("试点扶持产品")])],1):_vm._e()])])}),0),_c('el-pagination',{staticClass:"pagination",attrs:{"total":_vm.total,"current-page":_vm.page,"page-size":+_vm.pageSize,"layout":'prev, pager, next'},on:{"current-change":_vm.handlePageChange}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }