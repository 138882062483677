<template>
  <div class="container">
    <div class="same-container">
      <div class="size-container">
        <div class="public-container">
          <div class="top-nav">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                :to="{
                  path: '/company/sbzj',
                }"
                >申报征集</el-breadcrumb-item
              >
              <el-breadcrumb-item>试点意向企业入库申报</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="form-container">
            <div class="form-title-content">
              <h1 class="form-title">
                试点意向企业入库申报
              </h1>
              <div class="line"></div>
            </div>
            <el-form
              label-position="left"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">一、企业基本信息</h5>
              <el-form-item label="企业名称" prop="enterprise_name">
                <el-input
                  v-model="ruleForm.enterprise_name"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="所属行政区" prop="administrative_district">
                <el-input
                  v-model="ruleForm.administrative_district"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item label="所属细分行业" prop="sub_industry">
                <el-radio-group
                  v-model="ruleForm.sub_industry"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="智能网联和新能源汽车（零部件）"></el-radio>
                  <el-radio label="工业母机和机器人"></el-radio>
                  <el-radio label="时尚美妆"></el-radio>
                  <el-radio label="定制家居"></el-radio>
                  <el-radio label="服装"></el-radio>
                  <el-radio label="箱包"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="企业性质" prop="enterprise_nature">
                <el-radio-group
                  v-model="ruleForm.enterprise_nature"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="内资企业"></el-radio>
                  <el-radio label="外资企业"></el-radio>
                  <el-radio label="中外合资"></el-radio>
                  <el-radio label="其他"></el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- label="是否为中小企业" -->
              <el-form-item prop="is_small_medium_enterprise">
                <span slot="label">
                  是否为中小企业
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="（企业划型标准依据《工业和信息化部 统计局 发展改革委
                    财政部关于印发中小企业划型标准规定的通知》（工信部联企业〔2011〕300号））"
                    placement="top-start"
                  >
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                </span>
                <div class="sf-content">
                  <el-radio-group
                    style="display: flex;"
                    v-model="ruleForm.is_small_medium_enterprise"
                    size="medium"
                    :disabled="isDetail"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <!-- label="是否为制造业企业" -->
              <el-form-item prop="is_manufacturing_enterprise">
                <span slot="label">
                  是否为制造业企业
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="（行业划分标准依据《国民经济行业分类(GB/T 4754-2017)》）"
                    placement="top-start"
                  >
                    <i class="el-icon-warning"></i>
                  </el-tooltip>
                </span>
                <div class="sf-content">
                  <el-radio-group
                    style="display: flex;"
                    v-model="ruleForm.is_manufacturing_enterprise"
                    size="medium"
                    :disabled="isDetail"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item label="统一社会信用代码" prop="social_credit_code">
                <el-input
                  v-model="ruleForm.social_credit_code"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="企业类别（一）"
                prop="enterprise_category_one"
              >
                <el-radio-group
                  v-model="ruleForm.enterprise_category_one"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="专精特新“小巨人”企业"></el-radio>
                  <el-radio label="专精特新中小企业"></el-radio>
                  <el-radio label="创新型中小企业"></el-radio>
                  <el-radio label="其他中小企业"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业类别（二）"
                prop="enterprise_category_two"
              >
                <el-radio-group
                  v-model="ruleForm.enterprise_category_two"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="规上企业"></el-radio>
                  <el-radio label="规下企业"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="2023年营业收入（万元）"
                prop="annual_revenue"
              >
                <el-input
                  v-model="ruleForm.annual_revenue"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item label="员工数（截至申报日）" prop="employee_count">
                <el-input
                  v-model="ruleForm.employee_count"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item label="主营业务或主营产品" prop="main_business">
                <el-input
                  type="textarea"
                  v-model="ruleForm.main_business"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="所属细分行业开展业务证明材料"
                prop="business_evidence_material"
              >
                <div class="zmcl-content">
                  <el-input
                    type="textarea"
                    v-model="ruleForm.business_evidence_material"
                    :autosize="{ minRows: 4, maxRows: 8 }"
                    :disabled="isDetail"
                  ></el-input>
                  <div class="zmcl-upload">
                    <div class="zmcl-content-left">
                      <div
                        v-for="(item, index) in JSON.parse(
                          ruleForm.business_evidence_material_url
                        )"
                        :key="index"
                        class="flex vertical "
                        style="margin-bottom:8px;"
                      >
                        <el-link
                          type="primary"
                          target="_blank"
                          :href="item.url | contentMedia"
                          :download="item.name"
                          :underline="false"
                        >
                          {{ item.name }}
                        </el-link>
                        <i
                          class="el-icon-error dellink"
                          @click="handleDelFileBEM(index)"
                        ></i>
                      </div>
                    </div>
                    <div class="zmcl-content-right">
                      <el-upload
                        :action="uploadUrl"
                        :on-success="handleSuccessBEM"
                        :on-change="handleChangeBEM"
                        :file-list="fileListBEM"
                        :show-file-list="false"
                      >
                        <el-button
                          size="small"
                          type="primary"
                          :disabled="isDetail"
                          >选择文件</el-button
                        >

                        <div
                          slot="tip"
                          class="el-upload__tip"
                          style="text-wrap: nowrap;"
                        >
                          注:文件上传大小不能超过20M
                        </div>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="联系人" prop="contact_person">
                <el-input
                  v-model="ruleForm.contact_person"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item label="职务" prop="position">
                <el-input
                  v-model="ruleForm.position"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
              <el-form-item label="电话" prop="phone">
                <el-input
                  v-model="ruleForm.phone"
                  :disabled="isDetail"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-container">
            <el-form
              label-position="top"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm1"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">二、制造业中小企业数字化水平评估</h5>
              <el-form-item label="" prop="company_digitalize_assess.assess_lv">
                <el-radio-group
                  v-model="ruleForm.company_digitalize_assess.assess_lv"
                  size="medium"
                  :disabled="isDetail"
                >
                  <!-- <el-radio label="1级以下（20分以下）"></el-radio>
                  <el-radio label="1级（20分～40分）"></el-radio>
                  <el-radio label="2级（40分～60分）"></el-radio>
                  <el-radio label="3级（60分～80分）"></el-radio>
                  <el-radio label="4级（80分以上）"></el-radio> -->
                  <el-radio label="无等级"></el-radio>
                  <el-radio label="一级"></el-radio>
                  <el-radio label="二级"></el-radio>
                  <el-radio label="三级"></el-radio>
                  <el-radio label="四级"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label=""
                prop="company_digitalize_assess.assess_file"
              >
                <div class="sppgfj-upload">
                  <div class="sppgfj-content-left">
                    <div
                      v-for="(item, index) in JSON.parse(
                        ruleForm.company_digitalize_assess.assess_file
                      )"
                      :key="index"
                      class="flex vertical "
                      style="margin-bottom:8px;"
                    >
                      <el-link
                        type="primary"
                        target="_blank"
                        :href="item.url | contentMedia"
                        :download="item.name"
                        :underline="false"
                      >
                        {{ item.name }}
                      </el-link>
                      <i
                        class="el-icon-error dellink"
                        @click="handleDelFileAF(index)"
                      ></i>
                    </div>
                  </div>
                  <div class="sppgfj-content-right">
                    <el-upload
                      :action="uploadUrl"
                      :on-success="handleSuccessAF"
                      :on-change="handleChangeAF"
                      :file-list="fileListAF"
                      :show-file-list="false"
                    >
                      <el-button
                        size="small"
                        type="primary"
                        :disabled="isDetail"
                        >选择文件</el-button
                      >
                      <div
                        slot="tip"
                        class="el-upload__tip"
                        style="text-wrap: nowrap;"
                      >
                        注:文件上传大小不能超过20M
                      </div>
                    </el-upload>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-container">
            <el-form
              label-position="top"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm2"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">三、企业数字化转型意愿摸查</h5>
              <el-form-item
                label="企业数字化改造意愿强烈程度"
                prop="company_digitalize_agree.willingness_degree"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.willingness_degree"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="强烈"></el-radio>
                  <el-radio label="一般"></el-radio>
                  <el-radio label="较弱"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业改造后目标数字化水平"
                prop="company_digitalize_agree.target_digital_level"
              >
                <el-radio-group
                  v-model="
                    ruleForm.company_digitalize_agree.target_digital_level
                  "
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="二级"></el-radio>
                  <el-radio label="三级"></el-radio>
                  <el-radio label="四级"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业数字化转型第三方诊断及整体规划（含企业业务数字化痛点诊断、数字化相关产品选型、数字化转型规划建议等）"
                prop="company_digitalize_agree.third_party_diagnosis"
              >
                <el-radio-group
                  v-model="
                    ruleForm.company_digitalize_agree.third_party_diagnosis
                  "
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio :label="1">需要</el-radio>
                  <el-radio :label="0">不需要</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业工业数字化改造升级计划"
                prop="company_digitalize_agree.upgrade_plan"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.upgrade_plan"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="单机"></el-radio>
                  <el-radio label="产线"></el-radio>
                  <el-radio label="车间"></el-radio>
                  <el-radio label="工厂整体"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业通过数字化转型拟投资额度"
                prop="company_digitalize_agree.investment_amount"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.investment_amount"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="50万以下"></el-radio>
                  <el-radio label="100万以下"></el-radio>
                  <el-radio label="200万以下"></el-radio>
                  <el-radio label="300万以下"></el-radio>
                  <el-radio label="400万以下"></el-radio>
                  <el-radio label="500万以下"></el-radio>
                  <el-radio label="数字化车间标准（800万）"></el-radio>
                  <el-radio label="智能工厂标准（2000万）"></el-radio>
                  <el-radio label="链主工厂标准（3000万）"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业通过数字化转型拟提升的效益指标"
                prop="company_digitalize_agree.benefit_indicators"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.benefit_indicators"
                  size="medium"
                  :disabled="isDetail"
                >
                  <div class="radio-content">
                    <el-radio label="产品质量"></el-radio>
                    <el-radio label="生产效率"></el-radio>
                    <el-radio label="产品成本"></el-radio>
                    <el-radio label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.company_digitalize_agree
                            .benefit_indicators_other
                        "
                        :disabled="isDetail"
                      />
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业更倾向于哪种转型牵引模式？"
                prop="company_digitalize_agree.mode"
              >
                <el-radio-group
                  class="qyms-radio-group"
                  v-model="ruleForm.company_digitalize_agree.mode"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio class="qyms-radio-option" label="产业链模式">
                    <span>产业链模式</span>
                    <div class="qyms-description">
                      （该模式由具有较深行业制造知识和服务经验、较强行业资源整合能力的细分行业平台型企业，包括从制造业企业衍生的工业服务平台或深耕制造业细分产业链的第三方行业平台等为牵引单位，牵头开展本行业中小企业数字化改造实施。）
                    </div>
                  </el-radio>
                  <el-radio class="qyms-radio-option" label="供应链模式">
                    <span>供应链模式</span>
                    <div class="qyms-description">
                      (该模式由具有产业链供应链号召力、有强烈的供应链数字化提升意愿、能够辐射带动供应链中小企业的制造业骨干企业为牵引单位，牵头开展本行业中小企业数字化改造实施。)
                    </div>
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="在数字化转型方面最迫切的诉求（可多选）"
                prop="company_digitalize_agree.urgent_needs"
              >
                <el-checkbox-group
                  v-model="ruleForm.company_digitalize_agree.urgent_needs"
                  :disabled="isDetail"
                >
                  <div class="checkbox-content">
                    <el-checkbox label="获取低成本原材料采购渠道"></el-checkbox>
                    <el-checkbox label="获取更多市场订单"></el-checkbox>
                    <el-checkbox label="打通生产制造环节数据要素"></el-checkbox>
                    <el-checkbox label="提升供应链管理能力"></el-checkbox>
                    <el-checkbox label="提升企业经营管理能力"></el-checkbox>
                    <el-checkbox label="降低企业人力成本"></el-checkbox>
                    <el-checkbox label="提升产品研发设计能力"></el-checkbox>
                    <el-checkbox label="提升生产制造管理能力"></el-checkbox>
                    <el-checkbox label="产品质量全流程追溯"></el-checkbox>
                    <el-checkbox label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.company_digitalize_agree.urgent_needs_other
                        "
                        :disabled="isDetail"
                      />
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item
                label="企业当前急需进行数字化转型的业务场景或业务环节"
                prop="company_digitalize_agree.business_scenarios"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree.business_scenarios"
                  size="medium"
                  :disabled="isDetail"
                >
                  <div class="radio-content">
                    <el-radio label="研发设计"></el-radio>
                    <el-radio label="生产制造"></el-radio>
                    <el-radio label="仓储物流"></el-radio>
                    <el-radio label="市场营销"></el-radio>
                    <el-radio label="产品/服务"></el-radio>
                    <el-radio label="供应链管理"></el-radio>
                    <el-radio
                      label="运营管理（人力、财务、资产管理等）"
                    ></el-radio>
                    <el-radio label="其他：">
                      <span>其他：</span>
                      <el-input
                        class="ss-input"
                        v-model="
                          ruleForm.company_digitalize_agree
                            .business_scenarios_other
                        "
                        :disabled="isDetail"
                      />
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="企业当前数字化转型痛点难点或转型需求的描述"
                prop="company_digitalize_agree.notes"
              >
                <el-input
                  type="textarea"
                  placeholder="500字以内"
                  v-model="ruleForm.company_digitalize_agree.notes"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                  :disabled="isDetail"
                  maxlength="500"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="form-container">
            <el-form
              label-position="top"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm3"
              label-width="250px"
              class="ruleForm"
            >
              <h5 class="subheadings">四、意向数字化牵引单位选择</h5>
              <el-form-item
                label="企业意向合作数字化牵引单位"
                prop="company_digitalize_agree_select.company"
              >
                <div class="qydw-content">
                  <el-radio-group
                    class="qydw-radio"
                    v-model="ruleForm.company_digitalize_agree_select.company"
                    size="medium"
                    :disabled="isDetail"
                  >
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        智能网联和新能源汽车（零部件）行业：
                      </div>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州明珞装备股份有限公司（陈晓霞 18898608792）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="格创东智（广州）科技技术有限公司（刘舒妍 18813293359）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="浪潮云州工业互联网有限公司（罗嘉楠 18825188587）"
                      ></el-radio>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        工业母机和机器人行业：
                      </div>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州数控设备有限公司（翁晓芳 13316295118）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="树根互联股份有限公司（魏茂生 13726227688）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="广东玛斯特智能系统有限公司（赵瑾 18520139696）"
                      ></el-radio>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        时尚美妆行业：
                      </div>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州蜂巢互联科技有限公司（陈晓杰 18998291266）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州中浩控制技术有限公司（卢颖希 15011703065）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州环亚化妆品科技股份有限公司（袁首春 18922206198）"
                      ></el-radio>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        定制家居行业：
                      </div>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州尚品宅配家居股份有限公司（黄雪丹 13760963979）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="欧派家居集团股份有限公司（刘婷 13922220885）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州鼎捷软件有限公司（陶建林 13925119286）"
                      ></el-radio>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        服装行业：
                      </div>
                      <el-radio
                        class="qydw-radio-option"
                        label="比音勒芬服饰股份有限公司（胡蓉 17727617169）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="广东省纺织品进出口股份有限公司（宫兵 13922272819）"
                      ></el-radio>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州春晓信息科技有限公司（梁丽萍 13533123810）"
                      ></el-radio>
                    </div>
                    <div class="qydw-item">
                      <div class="qydw-item-title">
                        箱包行业：
                      </div>
                      <el-radio
                        class="qydw-radio-option"
                        label="广州盖特软件有限公司（曾怡 18928781587）"
                      ></el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </el-form-item>

              <el-form-item
                label="是否与所选数字化牵引单位或牵引单位服务生态企业已建立联系"
                prop="company_digitalize_agree_select.is_selected"
              >
                <el-radio-group
                  v-model="ruleForm.company_digitalize_agree_select.is_selected"
                  size="medium"
                  :disabled="isDetail"
                >
                  <el-radio label="是，已建立联系"></el-radio>
                  <el-radio label="否，需要引导对接建立联系"></el-radio>
                </el-radio-group>
              </el-form-item>
              <div class="btns">
                <el-button @click="handleClose" class="cancel-btn btn"
                  >关闭</el-button
                >
                <el-button
                  @click="saveDraft"
                  class="draft-btn btn"
                  v-if="!isDetail"
                  >保存草稿</el-button
                >
                <el-button
                  @click="getDraft"
                  class="draft-btn btn"
                  v-if="!isDetail"
                  >获取草稿</el-button
                >
                <el-button
                  @click="beforeSubmit"
                  type="primary"
                  class="btn"
                  v-if="!isDetail"
                  >提交</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import configs from "@/config/index";
import {
  saveDraft,
  getDraft,
  getCompanyInfo,
  searchTableAdd,
  searchTableDetail,
} from "@/api/pts";
export default {
  data() {
    return {
      ruleForm: {
        enterprise_name: "",
        administrative_district: "",
        sub_industry: null,
        enterprise_nature: null,
        is_small_medium_enterprise: null,
        is_manufacturing_enterprise: null,
        social_credit_code: "",
        enterprise_category_one: null,
        enterprise_category_two: null,
        annual_revenue: "",
        employee_count: "",
        main_business: "",
        business_evidence_material: "",
        business_evidence_material_url: "[]",
        contact_person: "",
        position: "",
        phone: "",
        company_digitalize_agree: {
          willingness_degree: null,
          target_digital_level: null,
          third_party_diagnosis: null,
          upgrade_plan: null,
          investment_amount: null,
          benefit_indicators: null,
          benefit_indicators_other: "",
          mode: null,
          urgent_needs: [],
          urgent_needs_other: "",
          business_scenarios: null,
          business_scenarios_other: "",
          notes: "",
        },
        company_digitalize_agree_select: {
          company: "",
          is_selected: null,
        },
        company_digitalize_assess: {
          assess_lv: null,
          assess_file: "[]",
        },
        // enterprise_name: "测试企业",
        // administrative_district: "行政区域111",
        // sub_industry: "时尚美妆",
        // enterprise_nature: "内资企业",
        // is_small_medium_enterprise: 1,
        // is_manufacturing_enterprise: 1,
        // social_credit_code: "12345789",
        // enterprise_category_one: "专精特新“小巨人”企业",
        // enterprise_category_two: "规上企业",
        // annual_revenue: "1000亿",
        // employee_count: "510",
        // main_business: "擦擦擦擦擦",
        // business_evidence_material: "所属细分行业开展业务证明材料666",
        // business_evidence_material_url: "[]",
        // contact_person: "猪猪侠",
        // position: "GGbond",
        // phone: "15615151515",
        // company_digitalize_agree: {
        //   willingness_degree: "强烈",
        //   target_digital_level: "二级",
        //   third_party_diagnosis: 1,
        //   upgrade_plan: "单机",
        //   investment_amount: "50万以下",
        //   benefit_indicators: "其他：",
        //   benefit_indicators_other: "其他东西",
        //   mode: "产业链模式",
        //   urgent_needs: ["获取更多市场订单", "降低企业人力成本", "其他："],
        //   urgent_needs_other: "其他小玩意儿",
        //   business_scenarios: "其他：",
        //   business_scenarios_other: "其他大玩意儿",
        //   notes: "500个字我哪里给你编出来呢",
        // },
        // company_digitalize_agree_select: {
        //   company: "广州盖特软件有限公司",
        //   is_selected: "是，已建立联系",
        // },
        // company_digitalize_assess: {
        //   assess_lv: "1级以下（20分以下）",
        //   assess_file: "[]",
        // },
      },

      rules: {
        // 一
        enterprise_name: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        administrative_district: [
          { required: true, message: "请输入所属行政区", trigger: "change" },
        ],
        sub_industry: [
          { required: true, message: "请输入所属细分行业", trigger: "change" },
        ],
        enterprise_nature: [
          { required: true, message: "请输入企业性质", trigger: "change" },
        ],
        is_small_medium_enterprise: [
          {
            required: true,
            message: "请输入是否为中小企业",
            trigger: "change",
          },
        ],
        is_manufacturing_enterprise: [
          {
            required: true,
            message: "请输入是否为制造业企业",
            trigger: "change",
          },
        ],
        social_credit_code: [
          { required: true, message: "请输入社会信用代码", trigger: "change" },
        ],
        enterprise_category_one: [
          {
            required: true,
            message: "请输入企业类别（一）",
            trigger: "change",
          },
        ],
        enterprise_category_two: [
          {
            required: true,
            message: "请输入企业类别（二）",
            trigger: "change",
          },
        ],
        annual_revenue: [
          {
            required: true,
            message: "请输入2023年营业收入（万元）",
            trigger: "change",
          },
        ],
        employee_count: [
          {
            required: true,
            message: "请输入员工数（截至申报日）",
            trigger: "change",
          },
        ],
        main_business: [
          {
            required: true,
            message: "请输入主营业务或主营产品",
            trigger: "change",
          },
        ],
        business_evidence_material: [
          {
            required: true,
            message: "请输入所属细分行业开展业务证明材料",
            trigger: "change",
          },
        ],
        contact_person: [
          { required: true, message: "请输入联系人", trigger: "change" },
        ],
        position: [
          { required: true, message: "请输入职务", trigger: "change" },
        ],
        phone: [{ required: true, message: "请输入电话", trigger: "change" }],

        //三
        "company_digitalize_assess.assess_lv": [
          {
            required: true,
            message: "请选择制造业中小企业数字化水平评估",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.willingness_degree": [
          {
            required: true,
            message: "请选择企业数字化改造意愿强烈程度",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.target_digital_level": [
          {
            required: true,
            message: "请选择企业改造后目标数字化水平",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.third_party_diagnosis": [
          {
            required: true,
            message: "请选择企业数字化转型第三方诊断及整体规划",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.upgrade_plan": [
          {
            required: true,
            message: "请选择企业工业数字化改造升级计划",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.investment_amount": [
          {
            required: true,
            message: "请选择企业通过数字化转型拟投资额度",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.benefit_indicators": [
          {
            required: true,
            message: "请选择企业通过数字化转型拟提升的效益指标",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.mode": [
          {
            required: true,
            message: "请选择企业更倾向于哪种转型牵引模式？",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.urgent_needs": [
          {
            required: true,
            message: "请选择在数字化转型方面最迫切的诉求",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.business_scenarios": [
          {
            required: true,
            message: "请选择企业当前急需进行数字化转型的业务场景或业务环节",
            trigger: "change",
          },
        ],
        "company_digitalize_agree.notes": [
          {
            required: true,
            message: "请输入企业当前数字化转型痛点难点或转型需求的描述",
            trigger: "change",
          },
        ],

        //四
        "company_digitalize_agree_select.company": [
          {
            required: true,
            message: "请选择企业意向合作数字化牵引单位",
            trigger: "change",
          },
        ],
        "company_digitalize_agree_select.is_selected": [
          {
            required: true,
            message:
              "请选择是否与所选数字化牵引单位或牵引单位服务生态企业已建立联系",
            trigger: "change",
          },
        ],
      },

      uploadUrl: "", // 图片上传路径
      fileListBEM: [],
      fileListAF: [],
      isDetail: false,
      loadingStatus: null,
    };
  },
  computed: {
    listType() {
      return Number(this.$route.query.type);
    },
  },
  created() {
    this.uploadUrl = `${configs.baseUrl}/api/common/upload`;
  },
  mounted() {
    // 1是填报，2是查看
    if (this.$route.query.type == 1) {
      this.isDetail = false;
      this.getCompanyInfo();
    } else if (this.$route.query.type == 2) {
      this.isDetail = true;
      this.searchTableDetail();
    }
  },
  methods: {
    searchTableDetail() {
      searchTableDetail().then((res) => {
        console.log("res", res);

        this.ruleForm = res.data;
        this.ruleForm.company_digitalize_agree.urgent_needs = JSON.parse(
          this.ruleForm.company_digitalize_agree.urgent_needs
        );
        this.fileListAF = JSON.parse(
          res.data.company_digitalize_assess.assess_file
        );
        this.fileListBEM = JSON.parse(res.data.business_evidence_material_url);
      });
    },
    getCompanyInfo() {
      getCompanyInfo().then((res) => {
        this.ruleForm.enterprise_name = this.ruleForm.enterprise_name
          ? this.ruleForm.enterprise_name
          : res.data.name;
        this.ruleForm.administrative_district = this.ruleForm
          .administrative_district
          ? this.ruleForm.administrative_district
          : res.data.belong_area;
        this.ruleForm.social_credit_code = this.ruleForm.social_credit_code
          ? this.ruleForm.social_credit_code
          : res.data.jgm;
        this.ruleForm.contact_person = this.ruleForm.contact_person
          ? this.ruleForm.contact_person
          : res.data.daily_person;
        this.ruleForm.position = this.ruleForm.position
          ? this.ruleForm.position
          : res.data.daily_job;
        this.ruleForm.phone = this.ruleForm.phone
          ? this.ruleForm.phone
          : res.data.daily_mobile;
        let level = "";
        const score = res.data.score;
        console.log("score", score);

        if (score < 20) {
          level = "1级以下（20分以下）";
        } else if (20 <= score && score < 40) {
          level = "1级（20分～40分）";
        } else if (40 <= score && score < 60) {
          level = "2级（40分～60分）";
        } else if (60 <= score && score < 80) {
          level = "3级（60分～80分）";
        } else if (80 <= score) {
          level = "4级（80分以上）";
        }
        this.ruleForm.company_digitalize_assess.assess_lv = this.ruleForm
          .company_digitalize_assess.assess_lv
          ? this.ruleForm.company_digitalize_assess.assess_lv
          : level;
      });
    },
    handleDelFileBEM(i) {
      if (this.isDetail) return;
      this.fileListBEM.splice(i, 1);
      this.ruleForm.business_evidence_material_url = JSON.stringify(
        this.fileListBEM
      );
    },
    handleSuccessBEM(response) {
      if (response.code == 1) {
        this.fileListBEM.push({
          name: response.data.name,
          url: response.data.fullurl,
        });
        this.ruleForm.business_evidence_material_url = JSON.stringify(
          this.fileListBEM
        );
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },

    handleChangeBEM(file, fileList) {
      console.log("handleChangeBEM", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        this.loadingStatus.close();
      }
    },
    handleDelFileAF(i) {
      if (this.isDetail) return;
      this.fileListAF.splice(i, 1);
      this.ruleForm.company_digitalize_assess.assess_file = JSON.stringify(
        this.fileListAF
      );
    },
    handleSuccessAF(response) {
      if (response.code == 1) {
        this.fileListAF.push({
          name: response.data.name,
          url: response.data.fullurl,
        });
        this.ruleForm.company_digitalize_assess.assess_file = JSON.stringify(
          this.fileListAF
        );
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handleChangeAF(file, fileList) {
      console.log("handleChangeAF", file, fileList);
      if (file.status == "uploading") {
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else if (file.status == "ready") {
        // console.log("do nothing");
        this.loadingStatus = this.$loading({
          lock: true,
          text: "上传中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
      } else {
        this.loadingStatus.close();
      }
    },
    beforeSubmit() {
      let flag = true;
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      this.$refs.ruleForm1.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      this.$refs.ruleForm2.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      this.$refs.ruleForm3.validate((valid) => {
        if (!valid) {
          flag = false;
        }
      });
      if (this.fileListAF.length == 0) flag = false;
      if (this.fileListBEM.length == 0) flag = false;
      if (flag) {
        console.log("beforeSubmit", this.ruleForm);
        this.ruleForm.company_digitalize_agree = JSON.stringify(
          this.ruleForm.company_digitalize_agree
        );
        this.ruleForm.company_digitalize_agree_select = JSON.stringify(
          this.ruleForm.company_digitalize_agree_select
        );
        this.ruleForm.company_digitalize_assess = JSON.stringify(
          this.ruleForm.company_digitalize_assess
        );
        searchTableAdd(this.ruleForm)
          .then((res) => {
            console.log("res", res);
            if (res.code == 1) {
              this.$message.success("申报成功");
              setTimeout(() => {
                this.handleClose();
              }, 1500);
            } else {
              this.$message.error("申报失败");
            }
          })
          .catch((err) => {
            console.log("add err!", err);

            this.$message.error("申报失败");
          });
      } else {
        this.$message.error("请填写完整表单");
      }
    },
    handleClose() {
      this.$router.push({ name: "Sbzj" });
    },
    saveDraft() {
      let pdata = {
        type: 5,
        content: JSON.stringify(this.ruleForm),
      };
      saveDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("保存草稿成功");
        } else {
          this.$message.error("保存草稿失败");
        }
      });
    },
    getDraft() {
      let pdata = {
        type: 5,
      };
      getDraft(pdata).then((res) => {
        if (res.code == 1) {
          this.$message.success("获取成功");
          let data = JSON.parse(res.data.content);
          this.ruleForm = data;
          this.fileListAF = JSON.parse(
            data.company_digitalize_assess.assess_file
          );
          this.fileListBEM = JSON.parse(data.business_evidence_material_url);
        } else {
          this.$message.error("获取失败");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ss-input {
  ::v-deep .el-input__inner {
    height: 18px;
    width: 100px;
    padding: 0 10px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #efefef;
  }
}
.container {
  background-color: #eff4fe;

  .same-container {
    width: 100%;
    height: 100%;
    background: #f0f5ff;
    background-size: 1920px 1000px;
    background-attachment: fixed;

    .size-container {
      width: 82%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;

        .top-nav {
          margin-bottom: 30px;
        }

        .form-container {
          width: 100%;
          padding: 40px 200px;
          background-color: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          margin-top: 30px;

          .form-title-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .form-title {
              font-family: Microsoft YaHei;
              font-size: 24px;
              font-weight: bold;
              line-height: normal;
              text-align: center;
              color: #333333;

              margin: 0;
              margin-bottom: 10px;
            }
            .line {
              width: 150px;
              height: 4px;
              border-radius: 2px;
              background: #205dc3;
            }
          }

          h5.subheadings {
            font-family: Microsoft YaHei;
            font-size: 22px;
            font-weight: bold;
            font-variation-settings: "opsz" auto;
            color: #333333;

            margin-top: 80px;
            margin-bottom: 40px;
          }
          .ruleForm {
            .sf-content {
              display: flex;
              // align-items: center;
              span {
                font-family: Microsoft YaHei;
                font-size: 16px;
                line-height: 18px;
                color: #666666;

                margin-left: 50px;
              }
            }

            .zmcl-content {
              .zmcl-upload {
                display: flex;
                justify-content: space-between;
                align-items: center;

                width: 100%;
                // height: 100px;
                padding: 15px;
                box-sizing: border-box;
                border-radius: 5px;
                border: 1px solid #dcdfe6;
                margin-top: 20px;

                .zmcl-content-left {
                  width: 70%;

                  font-family: Microsoft YaHei;
                  font-size: 16px;
                  line-height: 26px;
                  color: #2282f9;
                }
              }
            }

            .sppgfj-upload {
              display: flex;
              justify-content: space-between;
              align-items: center;

              width: 100%;
              // height: 100px;
              padding: 15px;
              box-sizing: border-box;
              border-radius: 5px;
              border: 1px solid #dcdfe6;
              margin-top: 20px;

              .sppgfj-content-left {
                width: 70%;

                font-family: Microsoft YaHei;
                font-size: 16px;
                line-height: 26px;
                color: #2282f9;
              }
            }

            .radio-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .other {
                margin-left: -20px;
                border-bottom: 1px solid #dcdfe6;

                /deep/.el-input__inner {
                  border: none;
                }
              }
            }

            .qyms-radio-group {
              display: flex;
              flex-direction: column;

              /deep/.qyms-radio-option {
                display: flex;
                // align-items: center;
                margin-bottom: 30px;
                .el-radio__label {
                  display: inline-block;
                  display: flex;
                  flex-direction: column;
                  .qyms-description {
                    // display: inline-block;
                    width: 100%;
                    // height: 100px;
                    margin-top: 10px;

                    font-family: Microsoft YaHei;
                    font-size: 14px;
                    line-height: 26px;
                    color: #666666;
                    text-wrap: wrap;
                  }
                }
              }
            }

            .checkbox-content {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              .other {
                // margin-left: -20px;
                border-bottom: 1px solid #dcdfe6;

                /deep/.el-input__inner {
                  border: none;
                }
              }
            }

            .qydw-content {
              .qydw-radio {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                margin-left: 30px;

                .qydw-item {
                  width: 350px;
                  display: flex;
                  flex-direction: column;
                  margin-right: 30px;

                  .qydw-item-title {
                    font-family: Microsoft YaHei;
                    font-size: 18px;
                    font-weight: bold;
                    font-variation-settings: "opsz" auto;
                    color: #333333;

                    margin-bottom: 30px;
                  }

                  .qydw-radio-option {
                    margin-bottom: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cancel-btn {
  height: 38px;
  background-color: #e2e2e2;
  border: none;
  color: #333;
  &:hover {
    background-color: #efefef;
    color: #333;
  }
}
.btns {
  margin-top: 38px;
  .draft-btn {
    height: 38px;
    background-color: #ea7100;
    border: none;
    color: #fff;
    &:hover {
      background-color: #ffb774;
      color: #fff;
    }
  }
  .btn {
    margin-right: 10px;
  }
}

.dellink {
  cursor: pointer;
  margin-left: 5px;
  color: #409eff;
  &:hover {
    color: #66b1ff;
  }
}
</style>
