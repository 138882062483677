<template>
  <div class="container" :style="$bgImg('sbzj-bg.png')">
    <div class="same-container">
      <div class="size-container">
        <div class="public-container">
          <div class="top-nav">
            <el-breadcrumb separator=">">
              <el-breadcrumb-item
                :to="{
                  path: '/government/sbzj',
                }"
                >申报征集</el-breadcrumb-item
              >
              <el-breadcrumb-item v-if="listType == 1"
                >数字化产品入库申报</el-breadcrumb-item
              >
              <el-breadcrumb-item v-else-if="listType == 2">
                试点意向企业入库申报</el-breadcrumb-item
              >
            </el-breadcrumb>
          </div>

          <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="审核通过" name="shtg"></el-tab-pane>
              <el-tab-pane label="审核不通过" name="shbtg"></el-tab-pane>
              <el-tab-pane label="待审核" name="dsh"></el-tab-pane>
            </el-tabs>
          </div>

          <div
            style="display: flex;justify-content: flex-end;width: 100%;margin: 10px 0;"
          >
            <!-- <el-button type="primary" @click="fuchiOnly"
              >只看扶持产品</el-button
            >
             -->
            <el-switch
              v-model="isFuChiOnly"
              active-text="只看扶持产品"
              active-color="#205dc3"
              inactive-color="#999"
              @change="fuchiOnly"
            >
            </el-switch>
          </div>

          <div class="list-content">
            <div
              class="list-item"
              v-for="(item, index) in dataList"
              :key="index"
              :style="$bgImg('bg_paper.png')"
            >
              <div class="item-left">
                <div class="title">
                  <span v-if="listType == 1"
                    >{{ item.company_name }}
                    {{ "（" + item.product_name + "）" }}</span
                  >
                  <span v-else-if="listType == 2">{{
                    item.enterprise_name
                  }}</span>
                </div>
                <div class="auditStatus" v-if="listType == 1">
                  <span v-if="item.audit_status == 1" style="color: orange;"
                    >待审核</span
                  >
                  <span v-else-if="item.audit_status == 2" style="color: green;"
                    >审核通过</span
                  >
                  <span v-else-if="item.audit_status == 3" style="color: red;"
                    >审核不通过</span
                  >
                </div>
                <div class="auditStatus" v-if="listType == 2">
                  <span
                    v-if="
                      item.company_digitalize_agree_select.audit_status == 1
                    "
                    style="color: orange;"
                    >待审核</span
                  >
                  <span
                    v-else-if="
                      item.company_digitalize_agree_select.audit_status == 2
                    "
                    style="color: green;"
                    >审核通过</span
                  >
                  <span
                    v-else-if="
                      item.company_digitalize_agree_select.audit_status == 3
                    "
                    style="color: red;"
                    >审核不通过</span
                  >
                </div>
                <div class="time">
                  <img class="time-icon" :src="'time.png' | staticMedia" />
                  <span>{{ item.updatetime }}</span>
                </div>
              </div>
              <div class="item-right">
                <!-- <div class="btn">查看详情</div> -->

                <div class="btns-container">
                  <el-button
                    type="primary"
                    class="pri-btn"
                    style="margin-right: 10px;"
                    @click="gotoForm(item)"
                    >查看详情</el-button
                  >
                  <el-popover
                    placement="top"
                    width="160"
                    :ref="item.id + 'popover'"
                  >
                    <p>确定删除吗？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="$refs[`${item.id}popover`].doClose()"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="deleteItem(`${item.id}popover`, item)"
                        >确定</el-button
                      >
                    </div>
                    <el-button slot="reference" type="danger">删除</el-button>
                  </el-popover>
                </div>
                <div
                  class="try-status"
                  style="margin-top: 10px;"
                  v-if="item.try_status == 1"
                >
                  <el-tag>试点扶持产品</el-tag>
                </div>
              </div>
            </div>
          </div>

          <!-- 分页 -->
          <el-pagination
            class="pagination"
            :total="total"
            :current-page="page"
            :page-size="+pageSize"
            :layout="'prev, pager, next'"
            @current-change="handlePageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getDeclarationList,
  getCompanyDigitalizeList,
  delDeclaration,
} from "@/api/zf";
import { formatDateTime } from "@/utils/common";
export default {
  data() {
    return {
      dataList: [],

      // 分页
      total: 0,
      page: 1,
      currentPage: 1,
      pageSize: 6, // 默认10个
      activeName: "shtg",
      status: 2,
      try_status: null,
      isFuChiOnly: false,
    };
  },
  created() {
    this.getList();
  },

  computed: {
    listType() {
      return Number(this.$route.query.type);
    },
  },
  methods: {
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    fuchiOnly() {
      if (this.isFuChiOnly) {
        this.try_status = 1;
      } else {
        this.try_status = null;
      }
      this.getList();
    },
    handleClick() {
      console.log("handleClick");
      if (this.activeName == "shtg") {
        this.status = 2;
      } else if (this.activeName == "shbtg") {
        this.status = 3;
      } else if (this.activeName == "dsh") {
        this.status = 1;
      }
      this.page = 1;
      this.try_status = null;
      this.getList();
    },
    getList() {
      if (this.listType == 1) {
        let pdata = {
          page: this.page,
          pageSize: +this.pageSize,
          status: this.status,
          try_status: this.try_status,
        };
        if (!this.try_status) {
          delete pdata.try_status;
        }
        getDeclarationList(pdata).then((res) => {
          if (res.code === 1) {
            console.log("this.datalist", res);
            if (res.data instanceof Array && res.data.length == 0) {
              this.dataList = [];
              this.total = 0;
              this.pageSize = 10;
              this.currentPage = 1;
            } else {
              res.data.data.forEach((item) => {
                item.updatetime = formatDateTime(item.updatetime);
              });
              this.dataList = res.data.data;
              this.total = res.data.total;
              this.pageSize = res.data.per_page;
              this.currentPage = +res.data.current_page;
            }
          }
        });
      } else if (this.listType == 2) {
        getCompanyDigitalizeList({
          page: this.page,
          pageSize: +this.pageSize,
          status: this.status,
        }).then((res) => {
          if (res.code === 1) {
            console.log("this.datalist", res);
            if (res.data instanceof Array && res.data.length == 0) {
              this.dataList = [];
              this.total = 0;
              this.pageSize = 10;
              this.currentPage = 1;
            } else {
              res.data.data.forEach((item) => {
                item.updatetime = formatDateTime(item.updatetime);
              });
              this.dataList = res.data.data;
              this.total = res.data.total;
              this.pageSize = res.data.per_page;
              this.currentPage = +res.data.current_page;
            }
          }
        });
      }
    },
    gotoIndex() {
      this.$router.push({
        path: "/government/sbzj",
        query: {
          type: this.listType,
        },
      });
    },
    gotoForm(item) {
      let path = "";
      if (this.listType == 1) {
        path = "/government/sbzj/form";
      } else {
        path = "/government/sbzj/formSecond";
      }
      this.$router.push({
        path: path,
        query: {
          type: this.listType,
          id: item.id,
        },
      });
    },
    deleteItem(refsName, item) {
      delDeclaration({ id: item.id })
        .then((res) => {
          // console.log(res);
          if (res.code === 1) {
            this.$message.success("删除成功");
          } else {
            this.$message.error("删除失败");
          }
        })
        .finally(() => {
          // console.log(refsName, this.$refs[refsName], "---====");

          this.$refs[refsName][0].doClose();
          this.getList();
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #eff4fe;
  background-size: cover;
  background-attachment: fixed;

  .same-container {
    width: 100%;
    height: 100%;
    // background: #f0f5ff;
    background-size: 1920px 1000px;
    background-attachment: fixed;

    .size-container {
      width: 82%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;

        .top-nav {
          margin-bottom: 30px;
        }

        .list-content {
          width: 100%;
          height: 100%;
          // padding-top: 30px;

          .list-item {
            width: 100%;
            height: 150px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            padding: 40px 40px 30px;
            box-sizing: border-box;
            background: #ffffff;
            background-position: 86% 150px;
            background-repeat: no-repeat;
            background-size: auto 130%;
            border-radius: 10px;
            margin-bottom: 20px;
            transition: all 0.5s;

            &:hover {
              background: #f3f7ff;
              background-position: 86% -25px;
              background-repeat: no-repeat;
              background-size: auto 130%;
              box-shadow: 0 0 10px #205dc375;

              .item-left {
                .title {
                  color: #2282f9;
                }
              }
            }

            .item-left {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .title {
                font-size: 20px;
                font-weight: 700;
                font-family: Microsoft YaHei;
                color: #333333;
                cursor: default;
                transition: all 0.5s;
              }
              .auditStatus {
                margin: 20px 0;

                font-size: 16px;
                font-weight: 500;
                font-family: Microsoft YaHei;
                color: #333333;
                cursor: default;
              }
              .time {
                font-family: Microsoft YaHei;
                font-size: 14px;
                font-weight: normal;
                line-height: 26px;
                letter-spacing: 0em;
                color: #333333;
                cursor: default;

                display: flex;
                align-items: center;

                .time-icon {
                  width: 25px;
                  height: 25px;

                  margin-right: 5px;
                }
              }
            }

            .item-right {
            }
          }
        }
      }
    }
  }
}

.tabs {
  width: 100%;
  // margin: 0 auto;
  ::v-deep .el-tabs {
    width: fit-content;
    margin: 0 auto 0;
    .el-tabs__item {
      font-size: 18px;
      padding: 0 50px;
      &.is-active {
        color: #205dc3;
      }
      &:hover {
        color: #205dc3;
      }
    }
    .el-tabs__nav {
      height: 48px;
      width: 478px;
      text-align: center;
    }
    .el-tabs__active-bar {
      width: 108px !important;
      height: 4px;
      left: -15px;
      background-color: #205dc3;
    }
    .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
  }
}
</style>
