<template>
  <div class="ContactUs">
    <div class="content_box">
      <div class="title">联系我们</div>
      <div class="subtitle">信息来源：本网 时间：2023-07-25</div>
      <div class="body">
        <p>单位名称：广州市“四化”促进联盟</p>
        <p>单位地址：广州市增城区朱村街朱村大道西78号</p>
        <p>邮政编码：511370</p>
        <p>
          办公时间：周一至周五，9:00 - 12:00，14:00 - 18:00 (节假日、公休日除外)
        </p>
        <p>
          网址：<a
            href="https://suizz.ceprei.com"
            target="_blank"
            title="本站网址"
            class="navgi"
            >https://suizz.ceprei.com</a
          >
        </p>
        <p>邮箱：kangyihan1187@163.com</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ContactUs",
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="less">
.content_box {
  width: 66%;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0 40px 50px;
  min-height: 521px;
  box-sizing: border-box;
  border: 1px solid #898989;
}

.content_box .title {
  font-size: 36px;
  font-weight: 600;
  color: #205dc3;
  line-height: 96px;
  text-align: center;
}

.content_box .subtitle {
  color: #898989;
  font-size: 14px;
  line-height: 36px;
  border-bottom: 1px solid #898989;
}

.content_box .body {
  padding: 30px 40px;
}

.content_box .body p {
  font-size: 18px;
  line-height: 36px;
  color: #3d3d3d;

  .navgi {
    color: #333;
    text-decoration: none;
  }
}
</style>
