import api from "./index";

// 政府-获取备案企业审核列表
export function getVerifyList_qy(data) {
  return api.post("/index/Government/companyApplyList", { data });
}
// 政府-获取内容审核列表
export function getVerifyList_nr(data) {
  return api.post("/index/government/infoCheckList", { data });
}

// 政府-备案企业审批
export function verify_qy(data) {
  return api.post("/index/Government/companyApplyCheck", { data });
}

// 政府-内容审批
export function verify_nr(data) {
  return api.post("/index/government/infoCheck", { data });
}

// 政府 - 内容审批获取内容详情
export function getNRDetail(data) {
  return api.post("/index/government/getEdit", { data });
}

// 政府-获取课程列表
export function getCourseList(data) {
  return api.post("/index/government/course", { data });
}

// 政府-获取课程详情
export function getCourseDetail(data) {
  return api.post("/index/government/courseInfo", { data });
}

// 政府-修改/增加课程
export function addOrEditCourse(data) {
  return api.post("/index/government/course_addOrEdit", { data });
}

// 政府-获取列表
export function getIndexList(data) {
  return api.post("/index/government/index", { data });
}

// 政府-添加内容
export function addContent(data) {
  return api.post("/index/Government/add", { data });
}

// 政府-内容详情回显
export function getDetail(data) {
  return api.post("/index/government/getEdit", { data });
}

// 政府-编辑内容
export function editContent(data) {
  return api.post("/index/Government/edit", { data });
}

// 政府-删除内容
export function delContent(data) {
  return api.post("/index/Government/del_journalism", { data });
}

// 政府-优秀案例发布
export function publishContent(data) {
  return api.post("/index/government/yxal", { data });
}

// 四化改造雷达
export function Get_getWjCategory(data) {
  return api.post("/index/Government/getWjCategory", { data });
}

export function Get_getStatistical1(data) {
  return api.post("/index/Government/getStatistical1", { data });
}
export function Get_getStatistical2(data) {
  return api.post("/index/Government/getStatistical2", { data });
}
// 鬼知道啥玩意
export function Get_nothing(url, data) {
  return api.post(url, { data });
}

// 四化诊断列表
export function fourDiagnoseList(data) {
  return api.post("/index/gov_diagnose/getDiagnose", { data });
}

// 四化诊断列表-变更信息
export function editDiagnose(data) {
  return api.post("/index/Government/diagnoseEdit", { data });
}

// 四化诊断列表-变更信息-诊断机构名称
export function getCompanyList(data) {
  return api.post("/index/Government/getCompany", { data });
}

// 四化诊断列表-变更信息-查看详情
export function getDiagnoseDetail(data) {
  return api.post("/index/gov_diagnose/info", { data });
}

// 四化诊断列表-雷达图
export function getRadarData(data) {
  return api.post("/index/Government/getRadarData", { data });
}

// 四化诊断列表-人员备案列表
export function getTerraceList(data) {
  return api.post("/index/gov_diagnose/terraceList", { data });
}

// 四化诊断列表-下载人员备案
export function terraceDownload(data) {
  return api.get(`/index/gov_diagnose/export?id=${data}`);
}

// 四化诊断列表
export function fourRemodelList(data) {
  return api.post("/index/government/xxcx", { data });
}
// 四化诊断列表
export function fourRemodelchart(data) {
  return api.post("/index/government/chart", { data });
}

// 政府端分页查询企业产品申报
export function getDeclarationList(data) {
  return api.post("/index/Declaration/index", { data });
}

// 政府端企业产品申报详情
export function getDeclarationInfoById(data) {
  return api.post("/index/Declaration/getInfoById", { data });
}

//删除产品申报
export function delDeclaration(data) {
  return api.post("/index/Declaration/deleteDeclarationById", { data });
}

// 政府端分页[改造摸底意向]
export function getCompanyDigitalizeList(data) {
  return api.post("/index/CompanyDigitalize/index", { data });
}
//企业意向入库的分页+模糊+审批接口【1：待审批】【2：审批通过】【3：不通过】
export function getCompanyDigitalizeObscureList(data) {
  return api.post("/index/CompanyDigitalize/checkStatuOne", { data });
}

// 政府端点击数据查看详情[摸底申请]
export function getCompanyDigitalizeById(data) {
  return api.post("/index/CompanyDigitalize/getInfoById", { data });
}
// 政府端审核产品申报
export function auditProductApplication(data) {
  return api.post("/index/Declaration/audit", { data });
}

// 政府端审核改造意向申报
export function govementUpdateCompany(data) {
  return api.post("/index/CompanyDigitalize/govementUpdateCompany", { data });
}

//批量审批企业意向
export function governmentBatchCheck(data) {
  return api.post("/index/CompanyDigitalize/governmentBatchCheck", { data });
}

//批量导出企业意向
export function exportCompanyDigitalizeFile(data) {
  return api.post("/index/CompanyDigitalize/exportCompanyDigitalizeFile", {
    data,
    responseType: "blob",
  });
}

// 企业下拉框
export function getImportCompanyList(params) {
  return api.get("/index/CompanyDigitalize/getImportCompanyList", { params });
}
// 产品申报获取总数
export function getDeclarationCount() {
  return api.get("/index/Declaration/getDeclarationCount");
}
// 产品申报获取总数
export function getcountCheckNumber() {
  return api.get("/index/CompanyDigitalize/countCheckNumber");
}
