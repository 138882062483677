<template>
  <div>
    <el-dialog
      title="中小企业数字化水平自评测"
      :visible.sync="isShowDialog"
      width="788px"
      top="3vh"
      :close-on-click-modal="false"
    >
      <div class="shuzihua-result">
        <div class="shuzihua-result-img">
          <img
            class="shuzihua-result-image"
            :src="'/resultbac.png' | staticMedia"
            alt=""
          />
        </div>
        <h3 class="shuzihua-result-title">企业数字化水平诊断结果</h3>
        <h4 class="shuzihua-result-subtitle">企业数字化水平等级</h4>
        <table>
          <tr>
            <th>数字化基础、管理及成效评分</th>
            <th>数字化经营应用场景等级</th>
            <th>测评等级</th>
          </tr>
          <tr>
            <td style="height: 30px;">{{ score }}</td>
            <td style="height: 30px;">{{ level1 }}</td>
            <td style="height: 30px;">{{ level2 }}</td>
          </tr>
        </table>

        <!-- <el-table :data="levelList"  style="width: 100%">
          <el-table-column prop="score" label="数字化基础、管理及成效评分">
          </el-table-column>
          <el-table-column prop="level1" label="数字化经营应用场景等级">
          </el-table-column>
          <el-table-column prop="level2" label="测评等级"></el-table-column>
        </el-table> -->
        <h4 class="shuzihua-result-subtitle2 flex center">
          数字化基础、管理及成效测评表
        </h4>

        <table border="1" cellspacing="0">
          <tr>
            <th>一级指标</th>
            <th>二级指标</th>
            <th>采集项</th>
            <th>得分</th>
          </tr>
          <!-- 暂无数据时显示 -->
          <tr v-if="!resultList1">
            <td :colspan="6">
              暂无数据
            </td>
          </tr>

          <tr>
            <td :rowspan="7"><div class="juzhong ">数字化基础</div></td>
            <td :rowspan="3">
              <div style="padding-top:40px ">设备系统</div>
            </td>
            <td>网络建设</td>
            <td>{{ trunscroe(resultList1, "网络建设") }}</td>
          </tr>
          <tr>
            <td>设备数字化</td>
            <td>{{ trunscroe(resultList1, "设备数字化") }}</td>
          </tr>
          <tr>
            <td>设备联网</td>
            <td>{{ trunscroe(resultList1, "设备联网") }}</td>
          </tr>
          <tr>
            <td>数据采集</td>
            <td>数据采集</td>
            <td>{{ trunscroe(resultList1, "数据采集") }}</td>
          </tr>
          <tr>
            <td>信息系统</td>
            <td>信息系统</td>
            <td>{{ trunscroe(resultList1, "信息系统") }}</td>
          </tr>
          <tr>
            <td :rowspan="2"><div style="padding-top:20px ">信息安全</div></td>
            <td>网络安全</td>
            <td>{{ trunscroe(resultList1, "网络安全") }}</td>
          </tr>
          <tr>
            <td>数据安全</td>
            <td>{{ trunscroe(resultList1, "数据安全") }}</td>
          </tr>
          <tr>
            <td :rowspan="4">
              <div style="padding-top:60px ">数字化管理</div>
            </td>
            <td :rowspan="2"><div style="padding-top:20px ">规划管理</div></td>
            <td>规划实施</td>
            <td>{{ trunscroe(resultList1, "规划实施") }}</td>
          </tr>
          <tr>
            <td>管理机制</td>
            <td>{{ trunscroe(resultList1, "管理机制") }}</td>
          </tr>
          <tr>
            <td :rowspan="2"><div style="padding-top:20px ">要素保障</div></td>
            <td>人才建设</td>
            <td>{{ trunscroe(resultList1, "人才建设") }}</td>
          </tr>
          <tr>
            <td>资金保障</td>
            <td>{{ trunscroe(resultList1, "资金保障") }}</td>
          </tr>
          <tr>
            <td :rowspan="4">
              <div style="padding-top:60px ">数字化成效</div>
            </td>
            <td>绿色低碳</td>
            <td>绿色低碳</td>
            <td>{{ trunscroe(resultList1, "绿色低碳") }}</td>
          </tr>
          <tr>
            <td>产品质量</td>
            <td>产品质量</td>
            <td>{{ trunscroe(resultList1, "产品质量") }}</td>
          </tr>
          <tr>
            <td :rowspan="2"><div style="padding-top:20px ">市场效益</div></td>
            <td>市场表现</td>
            <td>{{ trunscroe(resultList1, "市场表现") }}</td>
          </tr>
          <tr>
            <td>价值效益</td>
            <td>{{ trunscroe(resultList1, "价值效益") }}</td>
          </tr>
          <!-- <template v-for="(item, index) in tableInfo">
           
            <tr>
              <td :rowspan="item.orderItemList.length + 1">
                {{ item.firstlev }}
              </td>
              <td :rowspan="item.orderItemList.length + 1">
                {{ item.secondlev }}
              </td>
            </tr>
            
            <tr v-for="(child, index) in item.orderItemList">
              <td>
                {{ child.content }}
              </td>
              <td>
                {{ child.quantity }}
              </td>
              <td>{{ child.money }}元</td>
             
              <td :rowspan="item.orderItemList.length + 1" v-if="index == 0">
                <Button type="primary" size="small">支付</Button>
                <Button type="warning" size="small">取消</Button>
              </td>
            </tr>
          </template> -->
        </table>

        <h4 class="shuzihua-result-subtitle2 flex center">
          数字化经营应用场景等级判定表
        </h4>
        <table border="1" cellspacing="0">
          <tr>
            <th>一级指标</th>
            <th>二级指标（业务场景）</th>
            <th>等级</th>
          </tr>
          <!-- 暂无数据时显示 -->
          <tr v-if="!resultList2">
            <td :colspan="6">
              暂无数据
            </td>
          </tr>

          <tr>
            <td :rowspan="4">
              <div style="padding-top:60px ">产品生命周期数字化</div>
            </td>
            <td>产品设计*</td>
            <td>{{ trunscroe(resultList2, "产品设计*") }}</td>
          </tr>
          <tr>
            <td>工艺设计</td>
            <td>{{ trunscroe(resultList2, "工艺设计") }}</td>
          </tr>
          <tr>
            <td>营销管理*</td>
            <td>{{ trunscroe(resultList2, "营销管理*") }}</td>
          </tr>
          <tr>
            <td>售后服务</td>
            <td>{{ trunscroe(resultList2, "售后服务") }}</td>
          </tr>
          <tr>
            <td :rowspan="6">
              <div style="padding-top:100px ">生产执行数字化</div>
            </td>
            <td>计划排程</td>
            <td>{{ trunscroe(resultList2, "计划排程") }}</td>
          </tr>
          <tr>
            <td>生产管控*</td>
            <td>{{ trunscroe(resultList2, "生产管控*") }}</td>
          </tr>
          <tr>
            <td>质量管理</td>
            <td>{{ trunscroe(resultList2, "质量管理*") }}</td>
          </tr>
          <tr>
            <td>设备管理*</td>
            <td>{{ trunscroe(resultList2, "设备管理*") }}</td>
          </tr>
          <tr>
            <td>安全生产*</td>
            <td>{{ trunscroe(resultList2, "安全生产*") }}</td>
          </tr>
          <tr>
            <td>能耗管理*</td>
            <td>{{ trunscroe(resultList2, "能耗管理*") }}</td>
          </tr>
          <tr>
            <td :rowspan="2">
              <div style="padding-top:20px ">供应链数字化</div>
            </td>
            <td>采购管理*</td>
            <td>{{ trunscroe(resultList2, "采购管理*") }}</td>
          </tr>
          <tr>
            <td>仓储物流*</td>
            <td>{{ trunscroe(resultList2, "仓储物流*") }}</td>
          </tr>
          <tr>
            <td :rowspan="4">
              <div style="padding-top:60px ">管理决策数字化</div>
            </td>
            <td>财务管理*</td>
            <td>{{ trunscroe(resultList2, "财务管理*") }}</td>
          </tr>
          <tr>
            <td>人力资源</td>
            <td>{{ trunscroe(resultList2, "人力资源") }}</td>
          </tr>
          <tr>
            <td>协同办公</td>
            <td>{{ trunscroe(resultList2, "协同办公") }}</td>
          </tr>
          <tr>
            <td>决策支持</td>
            <td>{{ trunscroe(resultList2, "决策支持") }}</td>
          </tr>
        </table>

        <div class="description">
          <div>
            企业数字化经营应用场景等级判定标准：
          </div>
          <div>
            一级：企业应用信息技术工具辅助开展工作，实现相关业务的效率提升。
          </div>

          <div>
            二级：企业对关键生产环节开展在线数据采集和应用，基于信息化系统实现关键业务环节的数字化、规范化管理。
          </div>
          <div>
            三级：企业应用工业互联网等数字化技术开展信息和数据的实时采集和应用，通过跨部门、跨系统数据集成共享实现主要业务流程的数字化集成。
          </div>
          <div>
            四级：企业运用人工智能等前沿技术引领转型升级，全面实现人机物互联互通，打造孪生工厂、资源智能调度、供应链可视化协同等先进制造典型应用，构建基于数据应用的模型驱动生产运营模式，持续推进产业链协同
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSelftResult } from "@/api/qy";
import { format } from "echarts";
export default {
  name: "questionResult",

  props: {
    globalData: {
      type: Object,
    },
  },
  data() {
    return {
      resultList1: [],
      resultList2: [],
      isShowDialog: false,

      score: "",
      level1: 1,
      level2: 2,

      // 数据格式
      // 数据格式
      tableInfo: [
        {
          firstlev: "数字化基础",
          amount: "5300",
          secondlev: "设备系统",
          orderItemList: [
            {
              id: "113",
              content: "续费三年",
              quantity: "1",
              money: "3800",
            },
            {
              id: "114",
              content: "购买10个账号",
              quantity: "1",
              money: "1500",
            },
          ],
        },
        {
          firstlev: "数字化管理",
          amount: "2500",
          secondlev: "2018-11-01 17:20:25",
          orderItemList: [
            {
              id: "112",
              content: "续费一年",
              quantity: "1",
              money: "1000",
            },
            {
              id: "114",
              content: "购买10个账号",
              quantity: "1",
              money: "1500",
            },
          ],
        },
        {
          firstlev: "数字化成效",
          amount: "1000",
          secondlev: "2018-11-01 17:10:25",
          orderItemList: [
            {
              id: "111",
              content: "续费一年",
              quantity: "1",
              money: "1000",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    // this.getSZHQuestionResult();
  },
  methods: {
    getSZHQuestionResult() {
      let pdata = {
        questionId: 1,
        userId: JSON.parse(sessionStorage.getItem("user")).id,
      };
      getSelftResult(pdata)
        .then((res) => {
          console.log("res", res);
          if (res.data.length > 0) {
            this.score = res.data[0].score;
            this.level1 = res.data[1].levl;
            //返回小等级的数据
            this.level2 =
              this.panlevel(res.data[0].levl) < this.panlevel(res.data[1].levl)
                ? res.data[0].levl
                : res.data[1].levl;
            this.resultList1 = res.data[0].resultDetailScoreList;
            this.resultList2 = res.data[1].resultDetailScoreList;
          } else {
            this.score = " ";
            this.level1 = " ";
            this.level2 = " ";
          }
        })
        .finally(() => {
          this.isShowDialog = true;
        });
    },

    // 判断等级
    panlevel(level) {
      if (level == "无") {
        return 0;
      } else if (level == "一级") {
        return 1;
      } else if (level == "二级") {
        return 2;
      } else if (level == "三级") {
        return 3;
      } else if (level == "四级") {
        return 4;
      }
    },

    // 根据对应的名字返回对应的分数
    trunscroe(resultList, name) {
      if (resultList == this.resultList2) {
        for (let i = 0; i < resultList.length; i++) {
          if (resultList[i].name == name) {
            return resultList[i].level;
          }
        }
      } else {
        for (let i = 0; i < resultList.length; i++) {
          if (resultList[i].name == name) {
            return resultList[i].score;
          }
        }
      }
    },
  },
};
</script>

<style lang="less">
.shuzihua-result {
  padding: 0 50px 10px;
  .description {
    margin-top: 20px;
    line-height: 1.5;
  }
  .shuzihua-result-title {
    height: 42px;
    line-height: 42px;
    text-align: center;
    width: 100%;
    margin: 0;
  }
  .shuzihua-result-subtitle {
    height: 32px;
    line-height: 32px;
    font-weight: 400;
    margin: 0;
  }
  .shuzihua-result-image {
    width: 100%;
  }
  table {
    margin-top: 15px;
    width: 100%;
    border: 1px solid #e9eaec;
    border-collapse: collapse;
  }
  th {
    background-color: #f8f8f9;
  }
  th,
  td {
    padding: 5px;
    border: 1px solid #969799;
    text-align: center;
    vertical-align: top;
    line-height: 30px;
  }
  .shuzihua-part {
    padding: 10px 0;
    .shuzihua-part-title {
      font-size: 20px;
      font-weight: 600;
      color: #333;
      .blue-point {
        width: 10px;
        height: 10px;
        background-color: #205dc3;
        border-radius: 10px;
        margin: 0 5px;
      }
    }
    .shuzihua-part-text {
      line-height: 1.5;
      margin-top: 10px;
      margin-left: 20px;
      color: #333;
      .shuzihua-part-text-weight {
        color: #333;
        font-weight: 600;
      }
    }
    .shuzihua-part-table {
      width: 373px;
      margin: 10px auto;
      border: 1px solid #d8d8d8;
      .shuzihua-part-table-title {
        .shuzihua-table-title-item {
          width: 186px;
          height: 46px;
          line-height: 46px;
          background-color: #205dc3;
          text-align: center;
          color: #fff;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
      .shuzihua-part-table-list {
        &:last-child {
          .shuzihua-table-list-item {
            border-bottom: none;
          }
        }
        .shuzihua-table-list-item {
          width: 186px;
          height: 56px;
          line-height: 56px;
          color: #333;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;
          border-right: 1px solid #d8d8d8;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    .shuzihua-echarts {
      width: 600px;
      height: 500px;
      margin: 10px auto;
    }
  }
}
.juzhong {
  padding-top: 60%;
}
</style>
